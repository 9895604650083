import React from "react"

import SEO from "../layouts/seo"
import FullPageLoading from "../components/full-page-loading"

const NotFoundPage = () => (
  <main>
    <SEO title="Redirecting..." />
    <FullPageLoading />
  </main>
)

export default NotFoundPage
